<script>
export default {
  props: ["servicePage"],
};
</script>

<template>
  <div
    class="block sm:flex gap-0 sm:gap-10 mt-10 mb-10"
    :id="servicePage.section"
    v-if="servicePage.id % 2 !== 0"
  >
    <div class="w-full sm:w-2/3 mb-6 flex flex-col justify-center">
      <p
        class="text-3xl text-gray-800 font-general-semibold mb-3 dark:text-primary-light"
      >
        {{ servicePage.title }}
      </p>
      <p class="font-general-regular text-gray-400 text-justify mb-2">
        {{ servicePage.content1 }}
      </p>
      <ul class="list-disc font-general-regular text-gray-400 text-justify px-4 mb-2" v-for="unorderdList in servicePage.unorderdList1" :key="unorderdList.id">
        <li v-if="unorderdList.unorderList1 !== ''">{{ unorderdList.unorderList1 }}</li>
        <li v-if="unorderdList.unorderList2 !== ''">{{ unorderdList.unorderList2 }}</li>
        <li v-if="unorderdList.unorderList3 !== ''">{{ unorderdList.unorderList3 }}</li>
        <li v-if="unorderdList.unorderList4 !== ''">{{ unorderdList.unorderList4 }}</li>
        <li v-if="unorderdList.unorderList5 !== ''">{{ unorderdList.unorderList5 }}</li>
      </ul>
      <p class="font-general-regular text-gray-400 text-justify mb-2">
        {{ servicePage.content2 }}
      </p>
      <ul class="list-disc font-general-regular text-gray-400 text-justify px-4" v-for="unorderdList in servicePage.unorderdList2" :key="unorderdList.id">
        <li v-if="unorderdList.unorderList1 !== ''">{{ unorderdList.unorderList1 }}</li>
        <li v-if="unorderdList.unorderList2 !== ''">{{ unorderdList.unorderList2 }}</li>
        <li v-if="unorderdList.unorderList3 !== ''">{{ unorderdList.unorderList3 }}</li>
      </ul>
      <ol class="list-decimal font-general-regular text-gray-400 text-justify px-4" v-for="orderdList in servicePage.orderdList" :key="orderdList.id">
        <li v-if="orderdList.orderList1 !== ''">{{ orderdList.orderList1 }}</li>
        <li v-if="orderdList.orderList2 !== ''">{{ orderdList.orderList2 }}</li>
        <li v-if="orderdList.orderList3 !== ''">{{ orderdList.orderList3 }}</li>
      </ol>
    </div>
    <div class="w-full sm:w-1/3 text-left flex justify-center items-center">
      <div>
        <img
          :src="servicePage.img"
          :alt="servicePage.title"
          class="h-auto w-full rounded-xl shadow-lg hover:shadow-xl"
        />
      </div>
    </div>
  </div>
  <div
    class="block sm:flex gap-0 sm:gap-10 mt-6 mb-10"
    :id="servicePage.section"
    v-if="servicePage.id % 2 === 0"
  >
    <div class="w-full sm:w-1/3 text-left flex justify-center items-center">
      <div>
        <img
          :src="servicePage.img"
          :alt="servicePage.title"
          class="hidden relative lg:block h-auto w-full rounded-xl shadow-lg hover:shadow-xl"
        />
      </div>
    </div>
    <div class="w-full sm:w-2/3 mb-6 flex flex-col justify-center">
      <p
        class="text-3xl text-gray-800 font-general-semibold mb-3 dark:text-primary-light"
      >
        {{ servicePage.title }}
      </p>
      <p class="font-general-regular text-gray-400 text-justify mb-2">
        {{ servicePage.content1 }}
      </p>
      <p class="font-general-regular text-gray-400 text-justify mb-2">
        {{ servicePage.content2 }}
      </p>
      <p class="font-general-regular text-gray-400 text-justify mb-2">
        {{ servicePage.content3 }}
      </p>
      <ul class="list-disc font-general-regular text-gray-400 text-justify px-4" v-for="unorderdList in servicePage.unorderdList" :key="unorderdList.id">
        <li v-if="unorderdList.unorderList1 !== ''">{{ unorderdList.unorderList1 }}</li>
        <li v-if="unorderdList.unorderList2 !== ''">{{ unorderdList.unorderList2 }}</li>
        <li v-if="unorderdList.unorderList3 !== ''">{{ unorderdList.unorderList3 }}</li>
        <li v-if="unorderdList.unorderList4 !== ''">{{ unorderdList.unorderList4 }}</li>
        <li v-if="unorderdList.unorderList5 !== ''">{{ unorderdList.unorderList5 }}</li>
      </ul>
    </div>
    <img
      :src="servicePage.img"
      :alt="servicePage.title"
      class="lg:hidden lg:block h-auto w-full rounded-xl shadow-lg hover:shadow-xl"
    />
  </div>
</template>
