<script>
import ServicePageGrid from "../components/services/ServicePageGrid.vue";

export default {
  name: "Services",
  components: {
    ServicePageGrid,
  },
};
</script>

<template>
  <div class="container mx-auto">
    <ServicePageGrid />
  </div>
</template>
