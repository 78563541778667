<script>
import ServicePageSingle from "../services/ServicePageSingle.vue";
import services from "../../content/services";

export default {
  components: { ServicePageSingle },
  data: () => {
    return {
      services,
      servicesHeading: "Services Portfolio",
    };
  },
  computed: {
    filteredServices() {
      return this.services;
    },
  },
};
</script>

<template>
  <section class="pt-10 sm:pt-14">
    <div class="text-center">
      <p
        class="font-general-semibold text-5xl sm:text-5xl mb-2 text-ternary-dark dark:text-ternary-light"
      >
        {{ servicesHeading }}
      </p>
    </div>

    <div>
      <ServicePageSingle
        v-for="servicePage in filteredServices"
        :key="servicePage.id"
        :servicePage="servicePage"
      />
    </div>
  </section>
</template>
